<template>
  <el-dialog
    title="Orders"
    :fullscreen="true"
    :visible.sync="showPopup"
    class="my-dialog-class"
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <Methods-dialog
        ref="methods_dlg"
        @save="refresh_info()"
        @onSave="addMethods"
      />
      <Extra-dialog
        class="custom-width"
        ref="extra_dlg"
        @save="refresh_info()"
        @onSave="addExtra"
      />

      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item label="Created At" prop="CreatedAt">
            <el-date-picker
              class="full-width"
              v-model="selectedObject.CreatedAt"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="dd.MM.yyyy HH:mm"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Closed At" prop="ClosedAt">
            <el-date-picker
              class="full-width"
              v-model="selectedObject.ClosedAt"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="dd.MM.yyyy HH:mm"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="Table" prop="IdTable">
            <el-select v-model="selectedObject.IdTable" class="full-width">
              <el-option
                v-for="item in Info.tables"
                :key="'tables' + item.Id"
                :label="item.Name"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label="Closed">
            <el-select class="full-width" v-model="selectedObject.Closed">
              <el-option :key="0" label="No" value="0"></el-option>
              <el-option :key="1" label="Yes" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="24" style="display: flex">
          <el-form-item style="width: 100%"><h4>Products</h4> </el-form-item>
          <!-- <el-button
            type="primary"
            style="width: 125px; height: 50px; text-align: center"
            @click="showAddProducts"
            >Edit Products</el-button
          > -->
        </el-col>
        <el-col :md="24">
          <div class="container-fluid list-container">
            <div class="title">
              <div v-if="editMode === true" class="container-fluid">
                <i
                  class="el-icon-back"
                  style="
                    font-size: 25px;

                    color: #6493c2;
                    cursor: pointer;
                  "
                  @click="switchMode()"
                ></i>
                <div class="category-container">
                  <div v-for="prod in current_cat" :key="prod.Id">
                    <el-button
                      @click="addProduct(prod)"
                      class="cat-btn"
                      type="primary"
                      ><p style="margin: 0; padding: 0">
                        {{ prod.Name }}
                      </p></el-button
                    >
                  </div>
                </div>
              </div>

              <div v-if="editMode === false" class="category-container">
                <div v-for="cat in Info.Category" :key="cat.Id">
                  <el-button
                    class="cat-btn"
                    @click="switchMode(cat)"
                    type="primary"
                    >{{ cat.Name }}</el-button
                  >
                </div>
              </div>
              <div class="products-container">
                <div class="product-content">
                  <!-- aici vine tabela -->

                  <table class="table-list">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th style="text-align: right">Price</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(product, i) in Order.Products"
                      :key="product.Id + i"
                    >
                      <tr>
                        <td>{{ product.Name }}</td>
                        <td style="text-align: right">
                          {{ (product.Price * 1).toFixed(2) }} $
                        </td>
                      </tr>
                      <tr
                        style="font-size: 10px; padding 0px 10px"
                        v-if="product.Extra.length > 0"
                      >
                        <td>
                          Extra:
                          {{ product.Extra.map((e) => e.Name).join(", ") }}
                        </td>
                        <td style="text-align: right">
                          {{
                            product.Extra.map((e) => Number(e.Price))
                              .reduce((p, i) => p + i, 0)
                              .toFixed(2)
                          }}
                          $
                        </td>
                      </tr>
                      <tr
                        style="font-size: 10px; padding 0px 10px"
                        v-if="product.Methods !== ''"
                      >
                        <td colspan="2">Methods: {{ product.Methods }}</td>
                      </tr>

                      <tr>
                        <td colspan="2" style="text-align: right">
                          <div
                            style="
                              width: 100%;
                              margin: 0;
                              padding: 0;
                              diplay: flex;
                              justify-content: center;
                            "
                          >
                            <el-button
                              @click="add_methods(i, Info.Methods)"
                              class="list-btn"
                              >Methods</el-button
                            >
                            <el-button
                              @click="add_extra(i, Info.Extra)"
                              class="list-btn"
                              >Extra</el-button
                            >
                            <el-button
                              type="danger"
                              @click="deleteItem(i)"
                              class="list-btn"
                              >Delete</el-button
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- <el-col :md="24">
          <el-table
            header-align="right"
            :data="selectedObject.Products"
            style="width: 100%"
          >
            <el-table-column prop="Name" label="Name" width="180">
            </el-table-column>
            <el-table-column prop="Extra" label="Extra" width="130">
              <template slot-scope="scope">
                {{
                  scope.row.Extra.map((extra) => extra.Name).join(", ").length >
                  10
                    ? scope.row.Extra.map((extra) => extra.Name)
                        .join(", ")
                        .substring(0, 10)
                    : scope.row.Extra.map((extra) => extra.Name).join(", ")
                }}
              </template>
            </el-table-column>
            <el-table-column label="Method" prop="Methods"> </el-table-column>

            <el-table-column label="Actions" width="130">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="removeProduct(scope.$index)"
                  circle
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
        </el-col> -->
        <br />
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closePopup"> Close </el-button>
      <el-button type="primary" @click="save()"> Save </el-button>
    </span>

    <!-- <el-dialog
      title="Products"
      :visible.sync="showAddProduct"
      width="40%"
      height="70%"
      append-to-body
      v-if="mode === 'add'"
    >
      <el-table :data="Info.products" height="350px" style="width: 100%">
        <el-table-column prop="Name" label="Name" width="180">
        </el-table-column>
        <el-table-column prop="Extra" name="Extra" label="Extra">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="show_extra(scope.row.Extra, Info.extra, scope.$index)"
              circle
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="Methods" name="Methods" label="Methods">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="show_methods(scope.row, Info.methods)"
              circle
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column name="Actions">
          <template slot-scope="scope">
            <el-button type="primary" @click="addProductWhenAdd(scope.row)">
              Add
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddProducts()"> Close </el-button>
      </div>
    </el-dialog> -->

    <!-- <el-dialog
      title="Edit"
      :visible.sync="showAddProduct"
      width="40%"
      height="70%"
      append-to-body
      v-else
    >
      
      <el-table :data="Info.products" height="350px" style="width: 100%">
        <el-table-column prop="Name" label="Name" width="180">
        </el-table-column>
        <el-table-column prop="Extra" name="Extra" label="Extra">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="show_extra(scope.row.Extra, Info.extra, scope.$index)"
              circle
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="Methods" name="Methods" label="Methods">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="show_methods(scope.row, Info.methods)"
              circle
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column name="Actions">
          <template slot-scope="scope">
            <el-button type="primary" @click="addProductWhenAdd(scope.row)">
              Add
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddProducts()"> Close </el-button>
      </div>
    </el-dialog> -->
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Extra_dialog from "@/pages/orders/Extra.vue";
import Methods_dialog from "@/pages/orders/Methods.vue";

export default {
  name: "Orders_dialog",
  extends: BasePage,
  components: {
    "Extra-dialog": Extra_dialog,
    "Methods-dialog": Methods_dialog,
  },
  data() {
    return {
      form: {
        extra: "",
        numProd: 0,
      },
      baseUrl: "",
      showPopup: false,
      showAddProduct: false,
      showAddExtra: false,
      changes: false,
      Order: {},
      editMode: false,
      mode: "add",
      selectedObject: {
        CreatedAt: "",
        ClosedAt: "",
        Closed: "",
        IdTable: "",
        Products: [],
      },
      Info: {
        tables: [],
        products: [],
        Category: [],
        Methods: [],
        Extra: [],
      },
      rules: {
        CreatedAt: [{ required: true, message: "This field is required" }],
        IdTable: [{ required: true, message: "This field is required" }],
      },
    };
  },
  methods: {
    async switchMode(cat) {
      this.editMode = !this.editMode;
      if (cat === null) this.current_cat = null;
      if (this.editMode === true) {
        this.current_cat = cat.Products;
      }
    },

    addMethods(data) {
      let methods = data.methods;
      let index = data.index;
      var product = this.Order.Products[index];
      product.Methods = methods;
      this.changes = true;
    },

    addExtra(data) {
      let extra = data.extra;
      let index = data.index;
      let product = this.Order.Products[index];
      product.Extra = extra;
      this.changes = true;
    },

    deleteItem(data) {
      this.Order.Products.splice(data, 1);
      this.changes = true;
    },

    async add_methods(index, list) {
      let data = this.Order.Products[index].Methods;
      if (list === null) {
        list = this.Info.Methods;
      }
      this.$refs.methods_dlg.show_methods(data, list, index);
    },

    async add_extra(index, list) {
      let data = this.Order.Products[index].Extra;
      if (list === null) {
        list = this.Info.Extra;
      }
      console.log(data);
      this.$refs.extra_dlg.show_extra(data, list, index);
    },

    async addProduct(product) {
      this.Order.Products.unshift({
        Amount: "1",
        Extra: [],
        Id: product.Id,
        Methods: "",
        Name: product.Name,
        OrderNumber: product.OrderNumber,
        Price: product.Price,
      });
      this.changes = true;
    },

    async refresh_info() {
      this.loadingVisible = true;
      if (this.mode === "edit") {
        const res = await this.post("orders/get_order_by_id", {
          Id: this.IdOrder,
        });
        console.log(res);
        this.Order = {
          ClosedAt: res.Order.ClosedAt,
          CreatedAt: res.Order.CreatedAt,
          Closed: res.Order.close,
          Id: res.Order.Id,
          IdTable: res.Order.IdTable,
          Products: res.Order.Products,
        };
      } else {
        let obj = {
          ClosedAt: this.selectedObject.ClosedAt,
          Closed: this.selectedObject.Closed,
          CreatedAt: this.selectedObject.CreatedAt,
          IdTable: this.selectedObject.IdTable,
          Products: [],
        };
        this.Order = obj;
      }
      // console.log(this.Order);
      const category = await this.post("category/get_data");
      this.Info.Category = category;
      console.log("ASTA E DIN REFRESH INFO");
      console.log(this.Order.Products);
    },

    async get_info() {
      // var response = await this.post("orders/get_info_for_dialog");
      let response = await this.post("orders/get_info_for_dialog");
      this.Info.tables = response.tables;
      response.methods.forEach((methods) => {
        this.Info.Methods.push(methods);
      });

      response.extra.forEach((extra) => {
        this.Info.Extra.push(extra);
      });
    },

    async save() {
      if (!this.Order.Products || this.Order.Products.length <= 0) {
        return this.$message({
          type: "error",
          message: "Please insert at least one product!",
        });
      }
      let idTable = this.selectedObject.IdTable;
      let order = this.Order;
      order.IdTable = idTable;
      order.Closed = this.selectedObject.Closed;
      order.ClosedAt = this.selectedObject.ClosedAt;
      order.CreatedAt = this.selectedObject.CreatedAt;
      let mode = this.mode;
      let object = {
        IdTable: idTable,
        mode: mode,
        object: order,
      };
      console.log(object);
      if (mode === "add") {
        this.$confirm(
          "Your order will be created, you can edit it anytime. Do you want to continue?",
          "Warning",
          {
            confirmButtonText: "Place Order",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            let data = await this.post("orders/save", object);
            this.Order.Id = data;

            this.$message({
              type: "success",
              message: "Order created!",
            });
            this.closePopup();
            this.$router.go(0);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Order canceled",
            });
          });
      } else {
        this.$confirm(
          "Your order will be edited. Do you want to continue?",
          "Warning",
          {
            confirmButtonText: "Edit Order",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            let data = await this.post("orders/save", object);
            this.$message({
              type: "success",
              message: "Order updated!",
            });
            this.closePopup();
            this.$router.go(0);
          })
          .catch((e) => {
            this.$message({
              type: "info",
              message: "An error has occurred",
            });
            console.log(e);
          });
      }
      this.changes = 0;
    },

    // addExtra(data) {},
    // addMethods(data) {},
    // handleChange(obj) {
    //   let ids = [];
    //   ids = this.TempProducts.map((data) => data.Id);
    //   if (!ids.includes(obj.Id)) {
    //     this.TempProducts.push(obj);
    //   }
    // },
    // addProductWhenAdd: async function (product) {
    //   product.Amount = 1;
    //   this.showPopup = false;
    //   this.showPopup = true;
    //   this.selectedObject.Products.unshift(Object.assign({}, product));

    //   product.Extra = [];
    //   product.Methods = "";

    //   this.showAddProduct = !this.showAddProduct;
    // },
    closePopup: async function () {
      this.showPopup = false;
    },
    // resetPopup: function () {
    //   this.selectedObject = {};
    //   this.Info.products.forEach((product) => {
    //     product.Amount = 0;
    //     product.Methods = "";
    //     product.Extra = [];
    //   });
    // },
    // showAddProducts: async function () {
    //   this.showAddProduct = !this.showAddProduct;
    // },
    // removeProduct: async function (id) {
    //   let confirm = null;
    //   try {
    //     confirm = await this.$confirm("Are you sure?", "Warning");
    //   } catch (err) {
    //     confirm = null;
    //   }
    //   if (confirm) {
    //     this.selectedObject.Products.splice(id, 1);

    //     if (this.selectedObject.Products.length == 0)
    //       this.selectedObject.Products = [];
    //     this.showPopup = false;
    //     this.showPopup = true;
    //     this.Info.products.forEach((product) => {
    //       if (product.Id == id) {
    //         product.Amount = 0;
    //         product.Methods = "";
    //         product.Extra = [];
    //       }
    //     });
    //   }
    // },
    // async show_extra(data, list, index) {
    //   this.$refs.extra_dlg.show_extra(data, list, index);
    // },
    // async show_methods(data, list) {
    //   this.$refs.methods_dlg.show_methods(data, list);
    // },
    show_me: async function (id) {
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
        this.Order = {
          CreatedAt: "",
          ClosedAt: "",
          Closed: "",
          IdTable: "",
          Products: [],
        };
      } else {
        this.mode = "edit";
        var result = await this.post("orders/get_order_by_id", { Id: id });
        console.log(result);
        this.Order = result.Order;
        this.Order.Id = id;
        console.log(this.Order);
        this.selectedObject.ClosedAt = this.Order.ClosedAt;
        this.selectedObject.IdTable = this.Order.IdTable;
        this.selectedObject.CreatedAt = this.Order.CreatedAt;
        this.selectedObject.Closed = this.Order.Closed;
      }
    },
    // async get_info() {
    //   var response = await this.post("orders/get_info_for_dialog");
    //   this.Info.tables = response.tables;

    //   response.methods.forEach((data) => {
    //     this.Info.methods.push({
    //       Id: data.Id,
    //       Name: data.Name,
    //     });
    //   });

    //   response.extra.forEach((product) => {
    //     this.Info.extra.push({
    //       Id: product.Id,
    //       Name: product.Name,
    //       Price: product.Price,
    //       Amount: 1,
    //     });
    //   });

    //   response.products.forEach((product) => {
    //     this.Info.products.push({
    //       Id: product.Id,
    //       Name: product.Name,
    //       Price: product.Price,
    //       Amount: 0,
    //       Methods: "",
    //       Extra: [],
    //     });
    //   });
    // },
    // save: async function () {
    //   this.$refs["my-form"].validate(async (valid) => {
    //     let mode = this.mode;

    //     if (
    //       (this.selectedObject.ClosedAt == "" ||
    //         this.selectedObject.ClosedAt == "0000-00-00 00:00:00") &&
    //       this.selectedObject.Closed == 1
    //     ) {
    //       return this.$message({
    //         type: "error",
    //         message: "Closed At not set",
    //       });
    //     } else if (
    //       !(
    //         this.selectedObject.ClosedAt == "" ||
    //         this.selectedObject.ClosedAt == "0000-00-00 00:00:00"
    //       )
    //     ) {
    //       this.selectedObject.Closed = 1;
    //     }

    //     let selectedObject = this.selectedObject;
    //     let isFreeTable = await this.post("orders/verify_table", {
    //       object: selectedObject,
    //     });

    //     if (!isFreeTable && mode == "add") {
    //       return this.$message({
    //         type: "error",
    //         message: "The table already has one order",
    //       });
    //     }

    //     if (
    //       !this.selectedObject.Products ||
    //       this.selectedObject.Products.length == 0
    //     ) {
    //       return this.$message({
    //         type: "error",
    //         message: "Please insert at least one product",
    //       });
    //     }

    //     if (valid) {
    //       let data = await this.post("orders/save", {
    //         mode: mode,
    //         object: selectedObject,
    //       });
    //       this.selectedObject = {
    //         CreatedAt: "",
    //         ClosedAt: "",
    //         Closed: "",
    //         IdTable: "",
    //         Products: [],
    //       };
    //       this.Info.products.forEach((product) => {
    //         product.Amount = 0;
    //         product.Methods = "";
    //         product.Extra = [];
    //       });
    //       this.showPopup = false;
    //       this.$emit("save");
    //     }
    //   });
    // },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.refresh_info();
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.table-list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-container {
  max-height: 100vh;
  overflow: hidden;
}

.list-btn {
  width: 70px;
  padding: 3px 15px;
  text-align: center;
  font-size: 10px;
}

.category-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 10vh;

  /* width: 80% !important; */
  overflow: hidden;
  overflow-y: scroll;
  /* max-height: 200px; */
}
.list-container {
  margin: 0;
  padding: 15px 5px;
  width: 70% !important;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  gap: 5px;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 45vh;
}

.products-container {
  position: relative;
  margin: 0;
  padding: 10px;
  width: 80%;
  height: auto;

  margin-top: 50px;
  border-radius: 4px;
  background-color: #eaf0f7;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3rem;
}

.product-content {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #eaf0f7;
  max-height: 100%;
}

.product-title {
  height: 60px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  width: 100%;
  // height: 10vh;
}

.cat-btn {
  min-width: 11vw;
  height: 42px;
  margin: 0px;
  padding: 7px;
  font-size: 1rem;
}

.footer-container {
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 80%;
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  flex-direction: column;
  /* margin-right: 20px; */
}

@media only screen and (max-width: 600px) {
  /* Pentru telefon: */

  .main-container {
    max-height: 100vh;
    overflow: hidden;
  }

  .footer-container {
    width: 86%;
  }

  .list-container {
    max-height: 90vh;
  }

  .title {
    width: 100%;
    height: 10vh;
  }
  .category-container {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-height: 16vh;
  }
  .products-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 40vh;
    overflow: auto;
    /* min-height: 10vh; */
    width: 90%;
    font-size: 0.9rem;
  }

  .footer-btn {
    margin: 0;
    padding: 0;
    justify-content: center;
  }

  .footer-btn .el-button {
    width: 100px;
    text-align: center;
    font-size: 10px;
  }

  .cat-btn {
    /* width: fit-content; */
    width: auto;
    height: auto;
    margin: 0px;
    padding: 7px;
    font-size: 3.5vw;
  }
}

.footer-btn {
  display: flex;
  justify-content: flex-end;
}
</style>