<template>
  <el-dialog
    title="Methods"
    :visible.sync="showPopup"
    class="m-dlg"
    append-to-body
    :fullscreen="true"
  >
    <div class="body-container">
      <!-- Asta e partea de sus -->
      <input v-model="methods" class="text-area" type="text" />
      <hr />
      <!-- Iar asta e partea de jos -->
      <div class="scrollable">
        <div class="list-container">
          <el-button
            v-for="(methods, i) in Info.Methods"
            :key="methods.Id + i"
            class="extra-btn"
            @click="change(methods)"
            type="primary"
            plain
            round
          >
            {{ methods.Name }}
          </el-button>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()"> Close </el-button>
      <el-button @click="sendData()" type="primary"> Save </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Orders_dialog from "@/pages/orders/Orders_dialog.vue";
export default {
  name: "Methods_dialog",
  extends: BasePage,
  components: {
    "Order-dialog": Orders_dialog,
  },
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      methods: "",
      index: null,
      Info: {
        Methods: [],
      },
      Data: {},
    };
  },

  methods: {
    sendData() {
      let data = {
        methods: this.methods,
        index: this.index,
      };
      this.$emit("onSave", data);
      this.showPopup = false;
    },

    close() {
      this.methods = "";
      this.showPopup = false;
    },

    async show_methods(data, list, index) {
      this.methods = data;
      this.showPopup = true;
      this.index = index;
      this.Info.Methods = [];
      list.forEach((methods) => {
        this.Info.Methods.push(methods);
      });
    },

    change(data) {
      let text = data.Name + ";";
      this.methods = this.methods + " " + text;
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.element-container {
  width: 100%;
}

.list-container {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
}

.extra-btn {
  height: fit-content;
  margin-top: 10px;
  min-width: 200px;
}

.list-body {
  margin: 0;
  padding: 0 20px;
  margin-top: 30px;
  list-style: none;
  font-size: 18px;
  min-height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}

.list-body li {
  width: 100%;
  margin: 0;
  padding: 3px 3px;
}

.list-body li:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 10px;
}

.list-body li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selected {
  color: rgb(16, 157, 212);
}

.visible {
  display: none;
}

.scrollable {
  height: 100%;
}

.text-area {
  width: 100%;
  height: 100px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.body-container {
  // max-height: auto;
  height: 75vh;
}

.m-dlg {
  // position: inherit;
  // border: 20px solid red;
  // position: fixed;
  min-height: 100vh;
  // overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .m-dlg {
    width: 100% !important;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    // margin-left: -30vw;
  }

  .scrollable {
    height: 50vh;
    overflow-y: scroll;
  }
}
</style>

<!-- <template>
  <el-dialog
    title="Methods"
    :visible.sync="showPopup"
    class="my-dialog-class"
    width="40%"
    height="80%"
    append-to-body
  >

    <input v-model="Data.Methods" class="text-area" type="text" />
    <hr />

    <div class="list-container">
      <div v-for="(methods, i) in Info.Methods" :key="methods.Id + i">
        <el-button
          class="extra-btn"
          @click="change(methods)"
          type="primary"
          plain
          round
          >{{ methods.Name }}</el-button
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()"> Close </el-button>
      <el-button @click="sendData()" type="primary"> Save </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Orders_dialog from "@/pages/orders/Orders_dialog.vue";
export default {
  name: "Methods_dialog",
  extends: BasePage,
  components: {
    "Order-dialog": Orders_dialog,
  },
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      Info: {
        Methods: [],
      },
      Data: {},
    };
  },

  methods: {
    sendData() {
      let data = this.Data;
      this.$emit("onSave", data);
      this.close();
    },

    close() {
      this.Data = "";
      this.showPopup = false;
    },

    async show_methods(data, list) {
      this.Data = data;
      this.showPopup = true;

      if (this.Data.Methods != "")
        this.Data.Methods = this.Data.Methods.split("; ");
      else this.Info.Methods = [];

      list.forEach((methods) => {
        this.Info.Methods.push(methods);
      });

      if (!data) {
        this.mode = "add";
        this.Data.Methods = "";
      } else {
        this.mode = "edit";

        if (this.Data.Methods != "") {
          this.Data.Methods = data.Methods.join("; ");
        }
      }
    },

    change(data) {
      let text = data.Name + ";";

      this.Data.Methods = this.Data.Methods + " " + text;
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.element-container {
  width: 100%;
}

.list-container {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.extra-btn {
  margin-top: 10px;
  min-width: 200px;
}

.list-body {
  margin: 0;
  padding: 0 20px;
  margin-top: 30px;
  list-style: none;
  font-size: 18px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.list-body li {
  width: 100%;
  margin: 0;
  padding: 3px 3px;
}

.list-body li:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 10px;
}

.list-body li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selected {
  color: rgb(16, 157, 212);
}

.visible {
  display: none;
}

.text-area {
  width: 100%;
  height: 100px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
-->